import { Module } from 'vuex'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import productsEquals from 'src/modules/mageworx-giftcard/helpers/productEquals'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import Vue from 'vue'
import { createDiffLog, prepareShippingInfoForUpdateTotals } from '@vue-storefront/core/modules/cart/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem'
import { CartService } from '@vue-storefront/core/data-resolver'
import i18n from '@vue-storefront/core/i18n';

export const CartModule: Module<CartState, any> = {
  mutations: {
    /**
     * Add product to cart
     * @param {Object} product data format for products is described in /doc/ElasticSearch data formats.md
     */
    [types.CART_ADD_ITEM] (state, { product }) {
      const record = state.cartItems.find(p => productsEquals(p, product))
      if (!record) {
        let item = {
          ...product,
          qty: parseInt(product.qty ? product.qty : 1)
        }
        EventBus.$emit('cart-before-add', { product: item })
        state.cartItems.push(item)
      } else {
        EventBus.$emit('cart-before-update', { product: record })
        record.qty += parseInt((product.qty ? product.qty : 1))
      }
    },
    [types.CART_DEL_ITEM] (state, { product, removeByParentSku = true }) {
      EventBus.$emit('cart-before-delete', { items: state.cartItems })
      state.cartItems = state.cartItems.filter(p => !productsEquals(p, product) && (p.parentSku !== product.sku || removeByParentSku === false))
      EventBus.$emit('cart-after-delete', { items: state.cartItems })
    },
    [types.CART_DEL_NON_CONFIRMED_ITEM] (state, { product, removeByParentSku = true }) {
      EventBus.$emit('cart-before-delete', { items: state.cartItems })
      state.cartItems = state.cartItems.filter(p => (!productsEquals(p, product) && (p.parentSku !== product.sku || removeByParentSku === false)) || p.server_item_id/* it's confirmed if server_item_id is set */)
      EventBus.$emit('cart-after-delete', { items: state.cartItems })
    },
    [types.CART_UPD_ITEM] (state, { product, qty }) {
      const record = state.cartItems.find(p => productsEquals(p, product))

      if (record) {
        EventBus.$emit('cart-before-update', { product: record })
        record.qty = parseInt(qty)
        EventBus.$emit('cart-after-update', { product: record })
      }
    },
    [types.CART_UPD_ITEM_PROPS] (state, { product }) {
      let record = state.cartItems.find(p => productsEquals(p, product) || (p.server_item_id && p.server_item_id === product.server_item_id))
      if (record) {
        EventBus.$emit('cart-before-itemchanged', { item: record })
        Object.entries(product).forEach(([key, value]) => {
          Vue.set(record, key, value)
        })
        EventBus.$emit('cart-after-itemchanged', { item: record })
      }
    }
  },
  actions: {
    getItem ({ getters }, { product }) {
      return getters.getCartItems.find(p => productsEquals(p, product))
    },
    async checkProductStatus ({ dispatch, getters }, { product }) {
      const record = getters.getCartItems.find(p => productsEquals(p, product))
      const qty = record ? record.qty + 1 : (product.qty ? product.qty : 1)
      return dispatch('stock/queueCheck', { product, qty }, { root: true })
    },
    async mergeServerItem ({ dispatch, getters }, { clientItems, serverItem, forceClientState, dryRun }) {
      const diffLog = createDiffLog()
      const clientItem = clientItems.find(itm => productsEquals(itm, serverItem))
      if (clientItem) return diffLog
      Logger.info('No client item for' + serverItem.sku, 'cart')()
      diffLog.pushClientParty({ sku: serverItem.sku, status: 'no-item' })
      if (dryRun) return diffLog

      if (forceClientState) {
        Logger.info('Removing product from cart', 'cart', serverItem)()
        Logger.log('Removing item' + serverItem.sku + serverItem.item_id, 'cart')()
        const cartItem = {
          sku: serverItem.sku,
          item_id: serverItem.item_id,
          quoteId: serverItem.quote_id
        } as any as CartItem

        const resp = await CartService.deleteItem(getters.getCartToken, cartItem)
        return diffLog.pushServerResponse({ status: resp.resultCode, sku: serverItem.sku, result: resp })
      }

      const productToAdd = await dispatch('getProductVariant', { serverItem })
      if (productToAdd) {
        dispatch('addItem', { productToAdd, forceServerSilence: true })
        Logger.debug('Product variant for given serverItem has not found', 'cart', serverItem)()
      }

      return diffLog
    },
    async mergeClientItem ({ dispatch }, { clientItem, serverItems, forceClientState, dryRun, mergeQty }) {
      const serverItem = serverItems.find(itm => productsEquals(itm, clientItem))
      const diffLog = await dispatch('synchronizeServerItem', { serverItem, clientItem, forceClientState, dryRun, mergeQty })
      if (!diffLog.isEmpty()) return diffLog

      Logger.info('Server and client item with SKU ' + clientItem.sku + ' synced. Updating cart.', 'cart', 'cart')()
      if (!dryRun) {
        const product = {
          sku: clientItem.sku,
          server_cart_id: serverItem.quote_id,
          server_item_id: serverItem.item_id,
          product_option: serverItem.product_option,
          type_id: serverItem.product_type
        }

        await dispatch('updateItem', { product })
      }

      return diffLog
    }
  },
  getters: {
    isVirtualCart: ({ cartItems }) => cartItems.length ? cartItems.every(itm => {
      return itm.type_id === 'downloadable' || itm.type_id === 'virtual' || itm.mageworx_gc_type === '1' || itm.mageworx_gc_type === '2' // 1 and 2 gift card types are virtual
    }) : false
  }
}
