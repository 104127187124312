import { Route } from 'vue-router'
import { stamped } from 'config'

export function afterEach (to: Route, from: Route) {
  window.stampedLoaded = false
  let count = 0
  let stampedInterval

  function stampedRouteChangeHandler () {
    // Elements that Stamped Targets via data attributes
    const reviewElements = document.querySelectorAll('.stamped-main-badge')
    // Elements that Stamped injects into the DOM
    const badges = document.querySelectorAll('.stamped-badge')

    if (window.stampedLoaded || (badges.length && badges.length === reviewElements.length) || count > 5) {
      window.stampedLoaded = true
      clearInterval(stampedInterval)
    } else {
      if (
        window.StampedFn &&
        window.jQueryStamped &&
        reviewElements &&
        reviewElements.length
      ) {
        window.StampedGlobalOptions.apiKey = stamped.apiKey
        window.StampedGlobalOptions.storeUrl = stamped.domain

        window.StampedFn.init()
        window.StampedFn.loadBadges()
        window.StampedFn.loadDisplayWidgets()
        window.stampedLoaded = true
      }
      count++
    }
  }

  if (!window.stampedLoaded) {
    stampedInterval = setInterval(stampedRouteChangeHandler, 200)
  }
}
