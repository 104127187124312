import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { Module } from 'vuex';
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import config from 'config'
import UserExtendedState from '../types/UserExtendedState';
import * as types from './mutation-types'

export const UserExtendModule: Module<UserExtendedState, any> = {
  namespaced: true,
  state: {
    email: '',
    firstName: '',
    lastName: ''
  },
  mutations: {
    [types.SET_EMAIL] (state, email) {
      state.email = email
    },
    [types.SET_FIRST_NAME] (state, firstName) {
      state.firstName = firstName
    },
    [types.SET_LAST_NAME] (state, lastName) {
      state.lastName = lastName
    }
  },
  actions: {
    async isEmailAvailable ({ dispatch }, { email }) {
      let url = processLocalizedURLAddress('/api/ext/user-extend/is-email-available')
      url = config.storeViews.multistore ? adjustMultistoreApiUrl(url) : url
      let isAvailableResponse = await TaskQueue.execute({
        url,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            customerEmail: email
          })
        },
        silent: true
      })
      return isAvailableResponse.result ? isAvailableResponse.result : false
    },
    setGuestEmail ({ commit }, { email }) {
      commit(types.SET_EMAIL, email)
    },
    setFirstName ({ commit }, { firstName }) {
      commit(types.SET_FIRST_NAME, firstName)
    },
    setLastName ({ commit }, { lastName }) {
      commit(types.SET_LAST_NAME, lastName)
    }
  },
  getters: {
    getUserEmail (state) {
      return state.email ? state.email : ''
    },
    getFirstName (state) {
      return state.firstName ? state.firstName : ''
    },
    getLastName (state) {
      return state.lastName ? state.lastName : ''
    }
  }
}
