import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { stamped } from 'config'
import { isServer } from '@vue-storefront/core/helpers'
import { afterEach } from './router/AfterEach'
import { addEventListenerStamped } from './helpers'
import Vue from 'vue'
import { StampedPlugin } from './Plugins'

const stampedEvent = 'stamped:reviews:loaded'

declare global {
  interface Window {
    stampedLoaded: boolean,
    StampedGlobalOptions: any,
    StampedFn: any,
    jQueryStamped: any,
    stamped_disable_font: any
  }
}

export const Stamped: StorefrontModule = function ({ store, router }) {
  Vue.use(StampedPlugin)

  if (!isServer) {
    addEventListenerStamped(document, stampedEvent, (e) => {
      window.stampedLoaded = true
    })

    if (window.StampedGlobalOptions) {
      window.StampedGlobalOptions.apiKey = stamped.apiKey
      window.StampedGlobalOptions.storeUrl = stamped.domain
    }

    if (window.StampedFn) {
      window.stamped_disable_font = true
      window.StampedFn.init()
      window.stampedLoaded = true
    }

    Vue.prototype.$bus.$on('product-after-load', () => {
      Vue.prototype.$nextTick(() => {
        Vue.prototype.$loadStampedBadges()
      })
    })

    router.afterEach(afterEach)
  }
}
