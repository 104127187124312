import { processLocalizedURLAddress } from './../../../../core/helpers/index';
import Task from 'core/lib/sync/types/Task';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import config from 'config'

const headers = {
  'Accept': 'application/json, text/plain, */*',
  'Content-Type': 'application/json'
}

const getTransactionHistory = async (customerToken: string): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getTransactions)
    .replace('{{customerToken}}', customerToken)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}
export const getStoreCreditsDetails = async (customerToken: string): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.getStoreCreditsDetails)
    .replace('{{customerToken}}', customerToken)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'GET',
      mode: 'cors',
      headers
    }
  })
}

export const applyStoreCredits = async (data): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.applyStoreCredits)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'PUT',
      mode: 'cors',
      headers,
      body: JSON.stringify(data)
    }
  })
}

export const removeStoreCredits = async (data): Promise<Task> => {
  const url: string = processLocalizedURLAddress(config.storeCredits.endpoint.removeStoreCredits)

  return TaskQueue.execute({
    url: url,
    payload: {
      method: 'DELETE',
      mode: 'cors',
      headers,
      body: JSON.stringify(data)
    }
  })
}

export const StoreCreditsDataResolver = {
  getTransactionHistory,
  getStoreCreditsDetails,
  applyStoreCredits,
  removeStoreCredits
}
