import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { isServer } from '@vue-storefront/core/helpers'

const logRocket = {
  namespaced: true,
  state: {
    key: null
  }
};

export const LogRocketModule: StorefrontModule = function ({ store, appConfig }) {
  store.registerModule('log-rocket', logRocket)

  if (!isServer && appConfig.logRocket.apiKey) {
    let a = document.getElementsByTagName('head')[0];
    let r = document.createElement('script');
    r.text = "window.LogRocket && window.LogRocket.init('" + appConfig.logRocket.apiKey + "')"
    a.appendChild(r);
  }
}
