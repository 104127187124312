import { Module } from 'vuex'
import * as types from './mutation-types'
import { PRODUCT_SET_CURRENT } from '@vue-storefront/core/modules/catalog/store/product/mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from 'src/modules/mageworx-giftcard/types/ProductState'
import setCustomExtensionAttributes from 'src/modules/mageworx-giftcard//helpers/setCustomExtensionAttributes'

export const ProductModule: Module<ProductState, RootState> = {
  state: {
    current_extension_attributes: {}
  },
  mutations: {
    [types.PRODUCT_SET_EXTENSION_ATTRIBUTE] (state, { optionId, optionValue }) {
      state.current_extension_attributes = Object.assign({}, state.current_extension_attributes, { [optionId]: optionValue })
    }
  },
  actions: {
    setExtensionAttributes (context, { customOptions, product }) {
      if (customOptions) {
        context.commit(
          PRODUCT_SET_CURRENT,
          Object.assign({}, product, {
            product_option: setCustomExtensionAttributes(context, { product: product, customOptions: customOptions })
          })
        )
      }
    }
  },
  getters: {
    getCurrentExtensionAttributes: state => state.current_extension_attributes
  }
}
