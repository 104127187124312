import Vue from 'vue'
import VueGtm from 'vue-gtm'
import { Store } from 'vuex'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { isServer } from '@vue-storefront/core/helpers'
import { VueRouter } from 'vue-router/types/router'
import { getProduct } from '../helpers'
import isEqual from 'lodash-es/isEqual'
import debounce from 'lodash-es/debounce'

export const isEnabled = (gtmId: string | null) => {
  return typeof gtmId === 'string' && gtmId.length > 0 && !isServer
}

export function afterRegistration (config, store: Store<any>, router: VueRouter) {
  if (isEnabled(config.googleTagManager.id)) {
    const GTM: VueGtm = (Vue as any).gtm

    const storeView = currentStoreView()
    const currencyCode = storeView.i18n.currencyCode

    // Measuring Views of Product Details
    store.watch(
      (state) => state.product.current,
      debounce((newProduct, prevProduct) => {
        if (!isEqual(newProduct, prevProduct)) {
          GTM.trackEvent({
            event: 'viewProduct',
            ecommerce: {
              detail: {
                'actionField': { 'list': '' },
                'products': [getProduct(newProduct)]
              }
            }
          });
        }
      }, 200),
      {
        deep: false
      }
    )

    store.subscribe(({ type, payload }, state) => {
      // Adding a Product to a Shopping Cart
      if (type === 'cart/cart/ADD') {
        GTM.trackEvent({
          event: 'addToCart',
          ecommerce: {
            currencyCode: currencyCode,
            add: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Removing a Product from a Shopping Cart
      if (type === 'cart/cart/DEL') {
        GTM.trackEvent({
          event: 'removeFromCart',
          ecommerce: {
            currencyCode: currencyCode,
            remove: {
              products: [getProduct(payload.product)]
            }
          }
        });
      }

      // Measuring Checkout start
      if (type === 'route/ROUTE_CHANGED' && router.currentRoute.name.includes('checkout') && !router.currentRoute.hash) {
        GTM.trackEvent({
          event: 'Checkout',
          ecommerce: {
            checkout: {
              'step': 1
            }
          }
        });
      }

      // Measuring Purchases
      if (type === 'order/orders/LAST_ORDER_CONFIRMATION') {
        const orderId = payload.confirmation.backendOrderId
        const products = payload.order.products.map(product => getProduct(product))
        store.dispatch(
          'user/getOrdersHistory',
          { refresh: true, useCache: false }
        ).then(() => {
          const orderHistory = state.user.orders_history
          const order = state.user.orders_history ? orderHistory.items.find((order) => order['entity_id'].toString() === orderId) : null
          GTM.trackEvent({
            'event': 'orderPlaced',
            'ecommerce': {
              'purchase': {
                'actionField': {
                  'id': order ? order.increment_id : state.order.last_order_confirmation.confirmation.orderNumber,
                  'affiliation': order ? order.store_name : state.order.last_order_confirmation.order.store_code,
                  'revenue': order ? order.base_grand_total : state.cart.platformTotals && state.cart.platformTotals.base_grand_total ? state.cart.platformTotals.base_grand_total : '',
                  'tax': order ? order.base_tax_amount : state.cart.platformTotals && state.cart.platformTotals.base_tax_amount ? state.cart.platformTotals.base_tax_amount : '',
                  'shipping': order ? order.base_shipping_amount : state.cart.platformTotals && state.cart.platformTotals.base_shipping_amount ? state.cart.platformTotals.base_shipping_amount : '',
                  'coupon': order ? order.coupon_code : ''
                },
                'products': products
              }
            }
          })
        })
      }
    })
  }
}
