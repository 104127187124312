import { currentStoreView, localizedDispatcherRouteName, removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import { Payload } from '../types/Payload'
import { SearchQuery } from 'storefront-query-builder'

export const forProducts = async ({ dispatch }, { url, params }: Payload) => {
  const { storeCode, appendStoreCode } = currentStoreView()
  const productQuery = new SearchQuery()
  url = (removeStoreCodeFromRoute(url.replace(/^\//, '')) as string)
  productQuery.applyFilter({ key: 'url_path', value: { 'eq': url } }) // Tees category
  const products = await dispatch('product/list', { query: productQuery }, { root: true })
  if (products && products.items && products.items.length) {
    const product = products.items[0]
    return {
      name: localizedDispatcherRouteName(product.type_id + '-product', storeCode, appendStoreCode),
      params: {
        slug: product.slug,
        parentSku: product.sku,
        childSku: params['childSku'] ? params['childSku'] : product.sku
      }
    }
  }
}
