import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'

export const homepageStore = {
  namespaced: true,
  state: {
    featured: [],
    new_collection: [],
    bestsellers: []
  },
  actions: {
    async loadFeatured ({ commit, dispatch }) {
      const response = await dispatch('product/findProducts', {
        query: prepareQuery({ queryConfig: 'featured' }),
        size: 4,
        sort: 'position:asc'
      }, { root: true })

      commit('setFeatured', response.items)
    }
  },
  mutations: {
    setFeatured (state, products) {
      state.featured = products || []
    }
  },
  getters: {
    getFeatured (state) {
      return state.featured
    }
  }
}
