import { Module } from 'vuex'
import GoogleTagManagerState from '../types/GoogleTagManagerState'
import VueGtm from 'vue-gtm'
import Vue from 'vue'
import { getProduct } from '../helpers'
import { isEnabled } from '../hooks/afterRegistration'
import config from 'config'
import Product from 'core/modules/catalog/types/Product'

export const googleTagManagerModule: Module<GoogleTagManagerState, any> = {
  namespaced: true,
  state: {
    key: null
  },
  actions: {
    trackQuantityChange ({ rootState }, { product, qty }: { product: Product, qty?: number }) {
      if (isEnabled(config.googleTagManager.id)) {
        const GTM: VueGtm = (Vue as any).gtm
        const event = product.qty > qty ? 'removeFromCart' : 'addToCart'
        const action = product.qty > qty ? 'remove' : 'add'
        product.qty = 1 // this action will catch every modification, therefore qty change should be 1

        GTM.trackEvent({
          event: event,
          ecommerce: {
            [action]: {
              products: [getProduct(product)]
            }
          }
        })
      }
    },
    trackClearCart ({ rootGetters, rootState }) {
      if (isEnabled(config.googleTagManager.id)) {
        const GTM: VueGtm = (Vue as any).gtm
        const preparedProducts = []

        rootGetters['cart/getCartItems']?.forEach(product => {
          preparedProducts.push(getProduct(product));
        })

        if (preparedProducts.length) {
          GTM.trackEvent({
            event: 'removeFromCart',
            ecommerce: {
              currencyCode: rootState.storeView.i18n.currencyCode,
              remove: {
                products: preparedProducts
              }
            }
          })
        }
      }
    }
  }
}
