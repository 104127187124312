import { removeStoreCodeFromRoute, currentStoreView, localizedDispatcherRouteName } from '@vue-storefront/core/lib/multistore'
import { Payload } from '../types/Payload'

export const forCategoryOldFilter = async ({ dispatch }, { url }: Payload) => {
  const { storeCode, appendStoreCode } = currentStoreView()
  url = (removeStoreCodeFromRoute(url.replace(/^\//, '')) as string)
  let urlWithoutFilter = url.substr(0, url.lastIndexOf('/'))
  try {
    const category = await dispatch('category/single', { key: 'url_path', value: urlWithoutFilter }, { root: true })
    if (category !== null) {
      return {
        name: localizedDispatcherRouteName('category', storeCode, appendStoreCode),
        params: {
          slug: category.slug,
          filterAttribute: url.substr(url.lastIndexOf('/')).replace(/^\//, '')
        }
      }
    }
  } catch {
    return null
  }
}
