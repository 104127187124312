export default function setCustomExtensionAttributes (context, { product, customOptions }) {
  let productOption = {
    extension_attributes: {
      custom_options: [],
      configurable_item_options: [],
      bundle_options: [],
      mageworx_giftcard_item_option: {}
    }
  }
  productOption.extension_attributes.mageworx_giftcard_item_option = customOptions
  return productOption
}
