import { extendStore } from '@vue-storefront/core/helpers'
import { StorefrontModule } from '@vue-storefront/core/lib/modules'
import { Payload } from './types/Payload'
import { mappingList } from './builtin'

const cmsPageMapping = {
  namespaced: true
}

const cmsPageMappingModule = {
  actions: {
    async mappingFallback (context, payload: Payload) {
      for (const item of mappingList) {
        const result = await item(context, payload);
        if (result) {
          return result
        }
      }
    }
  }
}

export const VsfMappingFallback: StorefrontModule = function ({ store }) {
  store.registerModule('cmsPageMapping', cmsPageMapping)
  extendStore('url', cmsPageMappingModule)
}
