import getters from './getters'
import actions from './actions'
import mutations from './mutations'
export const storeCreditsStore = {
  namespaced: true,
  state: {
    store_credits_transactions: [],
    store_credits_details: null
  },
  getters,
  actions,
  mutations
}
