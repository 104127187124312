import { MutationTree } from 'vuex';
import * as types from './mutation-types'
import { NmiState } from '../types/NmiState';

export const mutations: MutationTree<NmiState> = {
  [types.SET_NMI_TOKEN] (state, payload) {
    state.nmiToken = payload
  },
  [types.SET_NMI_CARD_TYPE] (state, payload) {
    state.cardType = payload
  },
  [types.SET_NMI_CARD_NUMBER] (state, payload) {
    state.cardNumber = payload
  }
}
