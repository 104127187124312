import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { nmiStore } from './store'
import { afterRegistration } from './hooks/afterRegistration'

export const KEY = 'payment-nmi'

export const PaymentNmiModule: StorefrontModule = function ({ store, router, appConfig }) {
  store.registerModule(KEY, nmiStore)
  afterRegistration(appConfig, store)
}
