<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { isServer } from '@vue-storefront/core/helpers'
import { mapState } from 'vuex'
import config from 'config';
const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal')

export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout
  },
  data () {
    return {
      ordersData: []
    }
  },
  watch: {
    userData (state) {
      if (state && state.id && state.email) {
        window['LogRocket'].identify(state.id.toString(), {
          name: (state.firstname || '') + ' ' + (state.lastname || ''),
          email: state.email
        })
      }
    }
  },
  created () {
    if (!isServer) {
      window.addEventListener('storage', (e) => this.storageListener(e))
    }
  },
  destroyed () {
    if (!isServer) {
      window.removeEventListener('storage', (e) => this.storageListener(e))
    }
  },
  methods: {
    storageListener (e) {
      if (e.key === 'store/login/status') {
        if (e.newValue === 'started') {
          this.$bus.$emit('notification-progress-start', '')
          return
        }
        if (e.newValue === 'loggin' && !this.$store.getters['user/isLoggedIn']) {
          this.$store.dispatch('cart/clear', { sync: false }, { root: true })
        }
        this.$bus.$emit('notification-progress-start', '')
        this.$router.go(this.$router.currentRoute)
      }
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    },
    userData () {
      return this.$store.state.user.current || null
    }
  }
}
</script>
