import { ActionTree } from 'vuex'
import { StoreCreditsDataResolver } from '../data-resolver/store-credits-service'
import * as types from './mutation-types'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import StoreCreditsState from '../types/StoreCreditsState'

const actions: ActionTree<StoreCreditsState, RootState> = {

  async loadTransactionHistory (context) {
    let customerToken = rootStore.getters['user/getToken']

    const resp = await StoreCreditsDataResolver.getTransactionHistory(customerToken)

    if (resp.code === 200) {
      context.commit(types.USER_TRANSACTIONS_HISTORY_LOADED, resp.result)
    }

    return resp.result
  },
  async loadStoreCreditsDetails (context) {
    let customerToken = rootStore.getters['user/getToken']

    const resp = await StoreCreditsDataResolver.getStoreCreditsDetails(customerToken)

    if (resp.code === 200) {
      context.commit(types.USER_STORE_CREDITS_DETAILS_LOADED, resp.result)
    }

    return resp.result
  }
}

export default actions
