import { Module } from 'vuex';
import { NmiState } from '../types/NmiState';
import { getters } from './getters';
import { mutations } from './mutations'

export const nmiStore: Module<NmiState, any> = {
  namespaced: true,
  getters,
  mutations
}
