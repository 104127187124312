import RootState from 'core/types/RootState'
import { GetterTree } from 'vuex'
import StoreCreditsState from '../types/StoreCreditsState'

const getters: GetterTree<StoreCreditsState, RootState> = {
  getTransactions: state => state.store_credits_transactions,
  getStoreCreditsDetails: state => state.store_credits_details
}

export default getters
