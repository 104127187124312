import { Module } from 'vuex'
import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import {
  createDiffLog,
  createOrderData, createShippingInfoData,
  notifications,
  validateProduct
} from '@vue-storefront/core/modules/cart/helpers'
import config from 'config'
import { getProductOptions } from '@vue-storefront/core/modules/cart/helpers/productChecksum'
import { SearchQuery } from 'storefront-query-builder'
import { CartService } from '@vue-storefront/core/data-resolver'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers';
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { TaskQueue } from '@vue-storefront/core/lib/sync';
import rootStore from '@vue-storefront/core/store';
import { Logger } from '@vue-storefront/core/lib/logger';

export const CartModule: Module<CartState, any> = {
  actions: {
    async addItems ({ commit, dispatch, getters }, { productsToAdd, forceServerSilence = false }) {
      let productIndex = 0
      const diffLog = createDiffLog()
      for (let product of productsToAdd) {
        const errors = validateProduct(product)
        diffLog.pushNotifications(notifications.createNotifications({ type: 'error', messages: errors }))

        if (errors.length === 0) {
          const { status, onlineCheckTaskId } = await dispatch('checkProductStatus', { product })

          if (status === 'volatile' && !config.stock.allowOutOfStockInCart) {
            diffLog.pushNotification(notifications.unsafeQuantity())
          }
          if (status === 'out_of_stock' && product.stock.manage_stock) {
            diffLog.pushNotification(notifications.outOfStock())
          }

          if ((status === 'ok' || status === 'volatile') || (status === 'out_of_stock' && config.stock.allowOutOfStockInCart && !product.stock.manage_stock)) {
            commit(types.CART_ADD_ITEM, {
              product: { ...product, onlineStockCheckid: onlineCheckTaskId }
            })
          }
          if (productIndex === (productsToAdd.length - 1) && (!getters.isCartSyncEnabled || forceServerSilence)) {
            diffLog.pushNotification(notifications.productAddedToCart())
          }
          if (getProductOptions(product, 'custom_options').length && !product.totals) {
            forceServerSilence = false
          }
          productIndex++
        }
      }

      let newDiffLog = await dispatch('create')
      if (newDiffLog !== undefined) {
        diffLog.merge(newDiffLog)
      }

      if (getters.isCartSyncEnabled && getters.isCartConnected && !forceServerSilence) {
        const syncDiffLog = await dispatch('sync', { forceClientState: true })

        if (!syncDiffLog.isEmpty()) {
          diffLog.merge(syncDiffLog)
        }
      }

      return diffLog
    },
    async findProductOption ({ dispatch }, { serverItem }) {
      if (serverItem.product_type === 'configurable') {
        const sku = await dispatch('findProductSkuByQuery', { key: 'configurable_children.sku', value: serverItem.sku })
        return { sku: sku, childSku: serverItem.sku }
      }

      if (getProductOptions(serverItem, 'custom_options').length) {
        const sku = await dispatch('findProductSkuByQuery', { key: 'id', value: serverItem.extension_attributes.product_entity_id })
        return { sku: sku, childSku: serverItem.sku }
      }

      return { sku: serverItem.sku }
    },
    async findProductSkuByQuery ({ dispatch }, { key, value }) {
      let query = new SearchQuery()
      query = query.applyFilter({ key: key, value: { 'eq': value } })

      const { items } = await dispatch('product/findProducts', {
        query,
        start: 0,
        size: 1,
        options: {
          populateRequestCacheTags: false,
          prefetchGroupProducts: false,
          separateSelectedVariant: true
        }
      }, { root: true })

      return items.length ? items[0].sku : null
    },
    async applyCoupon ({ getters, dispatch, commit }, couponCode) {
      const existingCoupon = getters.getCoupon ? getters.getCoupon.code : null;
      if (couponCode && getters.canSyncTotals) {
        const { result, code } = await CartService.applyCoupon(couponCode)
        if (result && code === 200) {
          await dispatch('syncTotals', { forceServerSync: true })

          // 'getCurrentCartHash' has been changed (it's based on cart items data)
          // so we need to update it in vuex and StorageManager
          commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
        } else if (existingCoupon) {
          // Restore previous coupon
          await CartService.applyCoupon(existingCoupon)
        }
        return result
      }
    },
    async getFees ({ dispatch }) {
      let url = processLocalizedURLAddress('/api/ext/amasty-extrafees/get-fees')
      const cartId = rootStore.getters['cart/getCartToken'];
      const customerToken = rootStore.getters['user/getToken'];
      const address = rootStore.getters['checkout/getShippingDetails'];

      return TaskQueue.execute({
        url,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify({
            cart_id: cartId,
            customer_token: customerToken,
            addressInformation: {
              address: {
                region: '',
                region_id: 0,
                country_id: address.country,
                postcode: address.zipCode,
                city: address.city,
                firstname: address.shippingFirstName,
                lastname: address.shippingLastName
              }
            }
          })
        },
        silent: true
      })
    },
    async updateFee ({ getters, dispatch, commit }, { fee_id, options_ids }) {
      const url = processLocalizedURLAddress('/api/ext/amasty-extrafees/totals-information')
      const cart_id = rootStore.getters['cart/getCartToken'];
      const customerToken = rootStore.getters['user/getToken'];
      const address = rootStore.getters['checkout/getShippingDetails'];

      if (getters.canSyncTotals) {
        const { result, code } = await TaskQueue.execute({
          url,
          payload: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors',
            body: JSON.stringify({
              cart_id: cart_id,
              customer_token: customerToken,
              information: {
                fee_id: fee_id,
                options_ids: options_ids
              },
              addressInformation: {
                address: {
                  region: '',
                  region_id: 0,
                  country_id: address.country,
                  postcode: address.zipCode,
                  city: address.city,
                  firstname: address.shippingFirstName,
                  lastname: address.shippingLastName
                }
              }
            })
          },
          silent: true
        })

        if (result && code === 200) {
          await dispatch('syncTotals', { forceServerSync: true })
          commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash)
        }

        return result
      }
    },
    async syncTotals ({ dispatch, getters, rootGetters }, payload: { forceServerSync: boolean, methodsData?: any } = { forceServerSync: false, methodsData: null }) {
      const methodsData = payload ? payload.methodsData : null
      if (rootGetters['checkout/isUserInCheckout']) {
        await dispatch('pullMethods', { forceServerSync: payload.forceServerSync })
      }

      if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
        const shippingDetails = rootGetters['checkout/getShippingDetails'];
        const shippingMethodsData = methodsData || createOrderData({
          shippingDetails: shippingDetails,
          shippingMethods: rootGetters['checkout/getShippingMethods'],
          paymentMethods: rootGetters['checkout/getPaymentMethods'],
          paymentDetails: rootGetters['checkout/getPaymentDetails']
        })

        if (rootGetters['checkout/getShippingDetails'].extraFields && rootGetters['checkout/getShippingDetails'].extraFields.hasOwnProperty('delivery_date')) {
          if (!shippingMethodsData.hasOwnProperty('shippingAddress')) {
            shippingMethodsData.shippingAddress = {}
            shippingMethodsData.shippingAddress.extension_attributes = {}
          }
          shippingMethodsData.shippingAddress.extension_attributes.delivery_date = rootGetters['checkout/getShippingDetails'].extraFields.delivery_date
        }

        if (shippingMethodsData.country) {
          shippingMethodsData.method_code = shippingDetails?.shippingMethod || shippingMethodsData.method_code
          shippingMethodsData.carrier_code = shippingDetails?.shippingCarrier || shippingMethodsData.carrier_code
          await dispatch('overrideServerTotals', {
            hasShippingInformation: rootGetters['checkout/isUserInCheckout'] ? (shippingMethodsData.method_code || shippingMethodsData.carrier_code) : false,
            addressInformation: createShippingInfoData(shippingMethodsData)
          })
          return
        }

        Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')()
      }
    }
  }
}
