import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { CatalogModule } from '@vue-storefront/core/modules/catalog'
import { CatalogNextModule } from '@vue-storefront/core/modules/catalog-next'
import { CartModule } from '@vue-storefront/core/modules/cart'
import { CheckoutModule } from '@vue-storefront/core/modules/checkout'
import { NotificationModule } from '@vue-storefront/core/modules/notification'
import { UrlModule } from '@vue-storefront/core/modules/url'
import { BreadcrumbsModule } from '@vue-storefront/core/modules/breadcrumbs'
import { UserModule } from '@vue-storefront/core/modules/user'
import { CmsModule } from '@vue-storefront/core/modules/cms'
import { KlaviyoModule } from './vsf-klaviyo'
import { VsfMappingFallback } from './vsf-mapping-fallback'
import { UrlRewrites } from './url-rewrites'
import { Stamped } from './stamped'
import { StampedReviews } from './stamped-reviews'

import { GoogleAnalyticsModule } from './google-analytics'
import { GoogleTagManagerModule } from './google-tag-manager'
import { PaymentBackendMethodsModule } from './payment-backend-methods'
import { NewsletterModule } from '@vue-storefront/core/modules/newsletter'
import { InitialResourcesModule } from '@vue-storefront/core/modules/initial-resources'

import { DeviceModule } from './device'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { MageworxGiftcard } from './mageworx-giftcard'
import { MagentoMailerModule } from './magento-mailer'
import { PaymentPaypalModule } from './paypal';
import { PaymentNmiModule } from './payment-nmi';
import { ExtendedCart } from './cart-extended'
import { ExtendedNewsletter } from './newsletter-extended'
import { ExtendedUser } from './user-extend'
import { StoreCreditsModule } from './store-credits'
import { AheadworksBlog } from './aheadworks-blog'

import { AddressBook } from './address-book'
import { LogRocketModule } from './log-rocket'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules () {
  registerModule(UrlModule)
  registerModule(CatalogModule)
  registerModule(CheckoutModule) // To Checkout
  registerModule(CartModule)
  registerModule(PaymentBackendMethodsModule)
  registerModule(NotificationModule)
  registerModule(UserModule) // Trigger on user icon click
  registerModule(CatalogNextModule)
  registerModule(BreadcrumbsModule)
  registerModule(GoogleTagManagerModule)
  registerModule(GoogleAnalyticsModule)
  registerModule(CmsModule)
  registerModule(NewsletterModule)
  registerModule(InitialResourcesModule)
  registerModule(DeviceModule)
  registerModule(KlaviyoModule)
  registerModule(MageworxGiftcard)
  registerModule(MagentoMailerModule)
  registerModule(Stamped)
  registerModule(StampedReviews)
  registerModule(ExtendedCart)
  registerModule(ExtendedNewsletter)
  registerModule(ExtendedUser)
  registerModule(StoreCreditsModule)
  registerModule(VsfMappingFallback)
  registerModule(UrlRewrites)
  registerModule(AheadworksBlog)
  registerModule(LogRocketModule)
  registerModule(PaymentPaypalModule)
  registerModule(PaymentNmiModule)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  AddressBook
]
