import Vue from 'vue'
import { isServer } from '@vue-storefront/core/helpers'
import * as types from '../store/mutation-types'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

export function afterRegistration (config, store) {
  const VSF_NMI_CODE = 'rootways_nmi_option'
  let correctPaymentMethod = false

  const placeOrder = () => {
    if (correctPaymentMethod) {
      let additionalMethod = {
        nmi_payment_token: store.getters['payment-nmi/getNmiToken'](),
        cc_number: store.getters['payment-nmi/getCardNumber'](),
        cc_type: store.getters['payment-nmi/getCardType']()
      }
      EventBus.$emit('checkout-do-placeOrder', additionalMethod)
    }
  }

  if (!isServer) {
    EventBus.$on('checkout-before-placeOrder', placeOrder)

    EventBus.$on('checkout-payment-method-changed', (paymentMethodCode) => {
      correctPaymentMethod = paymentMethodCode === VSF_NMI_CODE
    })
  }
}
