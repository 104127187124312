import { Module } from 'vuex'
import * as types from './mutation-types'
import fetch from 'isomorphic-fetch'
import config from 'config'
import RootState from 'core/types/RootState'
import StampedReviewsState from '../types/StampedReviewsState'
import { processURLAddress } from '@vue-storefront/core/helpers'

export const module: Module<StampedReviewsState, RootState> = {
  namespaced: true,
  state: {
    reviews: [],
    summary: []
  },
  mutations: {
    [types.SET_REVIEWS] (state, payload) {
      state.reviews[payload.id] = payload.data
    },
    [types.SET_SUMMARY] (state, payload) {
      state.summary[payload.id] = payload.data
    }
  },
  actions: {
    async get ({ getters, commit, state }, { productId }) {
      if (!state.reviews[productId] || !state.reviews[productId].length) {
        const res = await fetch(processURLAddress(config.stampedReviews.get_endpoint.replace('{{productId}}', productId)))
        const json = await res.json()
        commit(types.SET_REVIEWS, {
          id: productId,
          data: json.result.reviews
        })
        commit(types.SET_SUMMARY, {
          id: productId,
          data: json.result.summary
        })
      }
    }
  }
}
