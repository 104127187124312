import CartState from '@vue-storefront/core/modules/cart/types/CartState'
import RootState from '@vue-storefront/core/types/RootState'
import { Module } from 'vuex'
import rootStore from '@vue-storefront/core/store'
import { StoreCreditsDataResolver } from '../../data-resolver/store-credits-service'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'

export const storeCreditsCheckoutModule: Module<CartState, RootState> = {
  namespaced: true,
  actions: {
    async applyStoreCredits (context) {
      if (context.getters.canSyncTotals) {
        const cartId = rootStore.getters['cart/getCartToken']
        const customerToken = rootStore.getters['user/getToken']

        const data = { 'customerToken': customerToken, 'cartId': cartId }

        const { result } = await StoreCreditsDataResolver.applyStoreCredits(data)
        if (result) {
          await context.dispatch('syncTotals', { forceServerSync: true })
          context.commit(types.CART_SET_ITEMS_HASH, context.getters.getCurrentCartHash)
        }

        return result
      }
      return Promise.resolve(null)
    },
    async removeStoreCredits (context) {
      const cartId = rootStore.getters['cart/getCartToken']
      const customerToken = rootStore.getters['user/getToken']

      const data = { 'customerToken': customerToken, 'cartId': cartId }

      const { result } = await StoreCreditsDataResolver.removeStoreCredits(data)
      if (result) {
        await context.dispatch('syncTotals', { forceServerSync: true })
        context.commit(types.CART_SET_ITEMS_HASH, context.getters.getCurrentCartHash)
      }

      return result
    }
  }
}
