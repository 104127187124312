import StoreCreditsState from '../types/StoreCreditsState'
import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import Transactions from '../types/Transactions'
import StoreCreditsDetails from '../types/StoreCreditsDetails'

const mutations: MutationTree<StoreCreditsState> = {
  [types.USER_TRANSACTIONS_HISTORY_LOADED] (state, transactions: Transactions[]) {
    state.store_credits_transactions = transactions
  },
  [types.USER_STORE_CREDITS_DETAILS_LOADED] (state, store_credits_details: StoreCreditsDetails) {
    state.store_credits_details = store_credits_details
  }
}

export default mutations
