import Vue from 'vue'
import { Logger } from '@vue-storefront/core/lib/logger'

export const StampedPlugin = {
  install (Vue, options) {
    Vue.prototype.$loadStampedBadges = () => {
      if (window && window.StampedFn) {
        Logger.info('refreshing stamped.io Star Badges from the plugin', 'Stamped')()
        window.StampedFn.loadBadges()
      }
    }

    Vue.prototype.$loadStampedWidgets = () => {
      if (window && window.StampedFn) {
        Logger.info('refreshing stamped.io Widgets from the plugin', 'Stamped')()
        window.StampedFn.init()
        window.StampedFn.loadDisplayWidgets()
      }
    }
  }
}
