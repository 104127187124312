import { GetterTree } from 'vuex';
import { NmiState } from '../types/NmiState'

export const getters: GetterTree<NmiState, any> = {

  getNmiToken: state => () => {
    return state.nmiToken
  },
  getCardType: state => () => {
    return state.cardType
  },
  getCardNumber: state => () => {
    return state.cardNumber
  }
}
