import { getProductOptions } from '@vue-storefront/core/modules/cart/helpers/productChecksum'
import config from 'config'

export const getProduct = (item) => {
  let product = {}

  const attributeMap: string[]|Record<string, any>[] = config.googleTagManager.product_attributes
  attributeMap.forEach(attribute => {
    const isObject = typeof attribute === 'object'
    let attributeField = isObject ? Object.keys(attribute)[0] : attribute
    let attributeName = isObject ? Object.values(attribute)[0] : attribute

    if (item.hasOwnProperty(attributeField) || product.hasOwnProperty(attributeName)) {
      const value = item[attributeField] || product[attributeName]
      if (value) {
        product[attributeName] = value
      }
    }
  })

  const { category } = item
  if (category && category.length > 0) {
    product['category'] = category.slice(-1)[0].name
  }

  // Custom product object handler if product has custom options
  if (item.custom_options?.length) {
    const selectedOptions: { option_id: string, option_value: string }[] = getProductOptions(item, 'custom_options')
    let mockedPrice = 0
    let mockedSku = item.sku

    if (selectedOptions.length) {
      selectedOptions.forEach(option => {
        const currentCustomOption = item.custom_options.find(availableOption => availableOption.option_id === Number(option.option_id))

        if (currentCustomOption?.values?.length) {
          const mockedValue = currentCustomOption.values.find(value => value.option_type_id === Number(option.option_value))

          mockedSku += `-${mockedValue?.sku}`
          mockedPrice += mockedValue?.special_price || mockedValue?.price || 0
        }
      })
    }

    product['sku'] = mockedSku
    product['price'] = mockedPrice
  }

  return product
}
